html {
    font-size: 15px;
    @media screen and (min-width: 320px) {
        font-size: calc(15px + 3 * ((100vw - 320px) / 680));
    }
    @media screen and (min-width: 1000px) {
        font-size: 15px;
    }
    body.pushable {
        .grecaptcha-badge{
            z-index: 1000;
        }
        background-color: #fff !important;
        height: auto;
        // ====================================================== //
        // ================ MODIF COLOR SYLIUS ================== //
        // ====================================================== //
        .ui.button.primary,
        .ui.basic.button.primary,
        .ui.labeled.button.primary,
        .ui.labeled.icon.button.primary,
        .buttons .ui.labeled.icon.button.primary,
        .ui.button.green,
        .ui.basic.button.green,
        .ui.labeled.button.green,
        .ui.labeled.icon.button.green,
        .buttons .ui.labeled.icon.button.green,
        .ui.button.submit,
        .ui.basic.button.submit,
        .ui.labeled.button.submit,
        .ui.labeled.icon.button.submit,
        .buttons .ui.labeled.icon.button.submit {
            background: #f78c2f !important;
            border-color: #f78c2f !important;
            &:hover {
                background: #f78c2fae !important;
                border-color: #f78c2f !important;
                color: #fff !important;
            }
        }
        .ui.ui.horizontal.divider{
            white-space: normal;
        }
        .ui.form input[type="text"]{
            padding: 0.5rem 1rem !important;
        } 
        #searchbarTextField{
            padding-right: 0;
            .sylius-filters__group .field input{
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }

        }
        #searchbarButtons{
            .buttons .ui.search-button {
                    border-radius: 0 !important;
            }
            .buttons .ui.dissmiss-button {
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
            }
            padding-left: 0;
        }
        
        .ui.breadcrumb a, .ui.huge.header::after, 
        .ui.horizontal.section.divider.header::after,
        .steps .active .title, 
        .steps .step.completed i::before {
            color: #f78c2f !important;
        }
        .ui.top.attached.large.tabular.menu .item.active{
            border-color: #f78c2f !important; 
        }
        a {
            color: #000000 !important;
            &:hover {
                color: #f78c2f !important;
            }
        }
        .btn-orange {
            background-color: #f78c2f;
            border-radius: 10px;
            align-self: center;
            color: #fff !important;
            &:hover, &:active {
                background-color: #f78c2f;
                color: #fff !important;
                box-shadow: inset -1px -1px 3px rgba(0, 0, 0, 0.35);
                border-radius: 10px;
                border-color: #db7d2b;
            }
        }
        .qte-change.minus.btn-orange{
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        .qte-change.plus.btn-orange{
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
        .ui.button.delete-item:hover{
            background: rgba(185, 34, 34, 0.173) !important;
                color: #ffffff !important;
                border-color: rgba(185, 34, 34, 0.173) !important;
        }
        // ====================================================== //
        // ==================== BASE+HOME ======================= //
        // ====================================================== //
        .top-c-bar {
            background-color: #fff;
            @media screen and (max-width: 1000px) {
                display: none;
            }
        }
        .header-custom {
            position: relative;
            .zone-menu {
                width: 100%;
                height: fit-content;
                &.bg-cus {
                    background-color: rgba(0, 0, 0, 0.95);
                }
                .container-fluid {
                    justify-content: end;
                    width: 100%;
                    @media screen and (max-width: 1000px) {
                        padding: 0;
                    }
                    .logo-cfa {
                        min-width: 10em;
                        max-width: 10em;
                        @media screen and (max-width: 1000px) {
                            display: none;
                        }
                    }
                    .navbar-collapse {
                        z-index: 1;
                        a {
                            color: #fff !important;
                            &:hover {
                                color: #deb82f !important;
                            }
                        }
                        a.nouveaute {
                            color: #ff6600 !important;
                            &:hover {
                                color: #fff !important;
                            }
                        }
                        &.collapsing,
                        &.show {
                            .navbar-nav {
                                @media screen and (max-width: 1000px) {
                                    background: rgba(0, 0, 0, 0.9);
                                    color: #deb82f !important;
                                    border: 0.1px solid #a18520;
                                    width: 100%;
                                }
                            }
                        }
                        .navbar-nav {
                            @media screen and (min-width: 1000px) {
                                align-items: center;
                            }
                        }
                    }
                }
                .no-border-btn {
                    border-radius: 4px;
                    padding: 10px 18px;
                    color: #fff;
                    font-size: 150%;
                    height: 2em;
                    @media screen and (max-width: 1000px) {
                        &:hover {
                            background: rgba(0, 0, 0, 0.9);
                            color: #deb82f !important;
                            border: 1px solid;
                            border-color: #a18520;
                        }
                        &.navbar-toggler {
                            height: 2.7em;
                            margin-right: 8px;
                        }
                    }
                }
                .profcart {
                    display: block;
                    @media screen and (min-width: 1000px) {
                        margin-left: auto;
                        display: flex;
                    }
                    .no-border-btn {
                        line-height: 0;
                        &:hover{
                            color: #deb82f !important;
                        }
                        .reduced-profil::before {
                            @media screen and (max-width: 1000px) {
                                content: " Profil";
                            }
                        }
                        .reduced-cart::before {
                            @media screen and (max-width: 1000px) {
                                content: " Panier";
                            }
                        }
                    }
                    .dropdown {
                        .dropdown-menu {
                            background: rgba(0, 0, 0, 0.9);
                            border-color: #a18520;
                            border-radius: 0;
                            width: 15rem;
                            li {
                                width: 100%;
                                padding: 0.2rem 2rem;
                                a {
                                    color: #fff;
                                    font-size: 1.1rem;
                                    &:hover {
                                        color: #a18520 !important;
                                        font-size: 1.1rem;
                                    }
                                }
                                div {
                                    color: #ffd333;
                                    font-size: 1.1rem;
                                }
                            }
                        }
                    }
                }
            }
            // ====================================================== //
            // ====================== CAROUSEL ====================== //
            // ====================================================== //
            .carousel-cus{
                width: 100%;
            }
            #carousel-home {
                @media screen and (max-width: 450px) {
                    display: none;
                }
                .carousel-bg {
                    background-color: rgba(0, 0, 0, 0.8);
                    width: 60%;
                    @media screen and (max-width: 1000px) {
                        width: 90%;
                    }
                    @media screen and (max-width: 800px) {
                        width: 100%;
                    }
                    .content-carousel {
                        padding: 3em 3em;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        @media screen and (max-width: 800px) {
                            padding: 2em 3em;
                            bottom: auto;
                        }
                        @media screen and (max-width: 650px) {
                            padding: 1em 3em;
                        }
                        h2 {
                            margin: 0;
                        }
                        h2,
                        p {
                            color: #fff;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .carousel-nav{
            button{
                i{
                    color: lightgrey;
                }
                &:hover{
                    i{
                        color: #f78c2f;
                    }
                }
            }
        }
        .container-custom {
            padding: 0 10%;
            @media screen and (max-width: 1000px) {
                padding: 0 2%;
            }
            // ====================================================== //
            // ====================== BOUTIQUES ===================== //
            // ====================================================== //
            .cat-card {
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
            .ui.pagination.menu .active.item{
                padding-top: 1.2em;
            }
            .cus-search-bar{
                    @media (min-width: 768px){
                    transform: translate(0,0)!important;
                    width: 500px !important;
                }
                .ui.primary.icon.labeled.button{
                    background-color: #f78c2f6e!important;
                    &:hover{
                        background-color: #f78c2f!important;
                    }
                }
            }
            .ui.monster.section.dividing.header{
                margin: none;
                margin-top: 2rem;
                margin-bottom: 2rem;
                max-width: none;
            }
            // ====================================================== //
            // ====================== MODIF PDT ===================== //
            // ====================================================== //
            .product-c-content{
                border: 1px solid #d9d9d9;
                img {
                    &.ui.bordered.image {
                        border: none;
                        padding-bottom: 3rem;
                        padding-top: 2rem;
                        width:50%;
                    }
                }
            }
            .side-cat{
                background-color: #ffe2c66e;
                border-right: 1px solid #d9d9d9;
            }
            .column{
                &.col-img{
                    flex-basis: 0 !important;
                }
                a{
                    #main-image{
                        max-width: 500px;
                    }
                }
                .ui.form input[type="number"] {
                    width: 120px;
                    margin-left: 1rem;
                }
            }
            .sylius-product-name{
                font-size: 1rem !important;
                font-weight: normal;
            }
            // ====================================================== //
            // ======================= PANIER ======================= //
            // ====================================================== //
            #sylius-cart-items {
                tbody {
                    tr {
                        td {
                            white-space: normal;
                        }
                        .qte-zone {
                            min-width: 120px;
                        }
                    }
                }
                tr,
                th,
                td {
                    border-left: none;
                }
                .qte-btn {
                    vertical-align: center;
                }
                .ui.form input[type="number"] {
                    width: 70px;
                }
            }
            .addrframe {
                @media screen and (max-width: 1000px) {
                    height: 400px;
                }
            }
        }
        .sixteen.wide.mobile.four.wide.computer.column {
            display: none;
        }
        // ====================================================== //
        // ======================= FOOTER ======================= //
        // ====================================================== //
        #footer {
            background-color: #2b2b2b;
            a {
                color: #fff !important;
                font-size: 130%;
                &:hover {
                    color: #f78c2f !important;
                }
            }
        }
        // ====================================================== //
        // ======================= SLICK ======================== //
        // ====================================================== //
        .slick-track {
            display: flex;
            align-items: stretch;
        }
        .slick-slide {
            height: auto;
        }
        .slick-slide::nth-child(1) {
            height: 100%;
        }


    }
}
